import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Home } from "./pages/home";
import { OurKreators } from "./pages/OurKreators";
import {FAQs} from "./pages/FAQs"
import { Portfolio } from "./pages/Portfolio";
import { Services } from "./pages/Services";
import { NavBar } from "./components/Nav";
import { Thanks } from "./pages/thanks";
import CustomCursor from './components/Custom';
import Cursor from "./components/Custom";
import ScrollProgressCircle from "./components/CircularProgress";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@fortawesome/fontawesome-free/css/all.css";




function App() {
  return (
    <div className="App">
      <div class="cursor "></div>

      <Router>
        <div className="pages">
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/OurKreators" element={<OurKreators />}></Route>
            <Route path="/Portfolio" element={<Portfolio />}></Route>
            <Route path="/FAQs" element={<FAQs />}></Route>
            <Route path="/Services" element={<Services />}></Route>
            <Route path="/thank-you" element={<Thanks/>}></Route>
          </Routes>
        </div>
        {/* <CustomCursor /> */}
        <Cursor></Cursor>
        <div className="fixed top-0">
          <ScrollProgressCircle />
        </div>
      </Router>
    </div>
  );
}

export default App;
