import React, { useState, useEffect } from "react";

export const Accordion = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const rotateArrow = (index) => {
    return activeIndex === index ? "rotate" : "";
  };
  return (
    <div>
      <div className={`accordion-fq ${props.style=== 'white' ?'text-white':''}`} >
        {props.item.map((itemAcc, index, ) => (
          <div key={index} className="accordion-item">
            <div
              className={`accordion-header-fq flex justify-between items-center ${
                activeIndex === index ? "accordion-active" : ""
              }`}
              onClick={() => handleClick(index)}
            >
              <h3 className="mt-2 mb-3 text-lg font-bold leading-6 cursor-pointer sm:text-xl">{itemAcc.title}</h3>
              <span
                className={`ml-2 transition-transform duration-200 transform   ${rotateArrow(
                  index
                )}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6 opacity-50"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m15 11.25-3-3m0 0-3 3m3-3v7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            </div>
            {activeIndex === index && (
              <div className="accordion-content">
                <p className="mb-3 leading-6 sm:text-lg">{itemAcc.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
