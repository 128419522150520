// ChipButtons.js
import React from 'react';

const ChipButtons = ({ labels, sortImages, selectedLabel }) => {
  return (
    <div className="flex mt-4 mb-5 space-x-2">
      {labels.map((label) => (
        <button
          key={label}
          onClick={() => sortImages(label)}
          className={`px-4 py-2 rounded-full focus:outline-none 
            ${selectedLabel === label ? 'bg-black text-white' : 'bg-white text-black  border border-gray-200'}
            hover:bg-black hover:text-white`}
        >
          {label}
        </button>
      ))}
    </div>
  );
};

export default ChipButtons;
