import React, { useEffect, useState } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useGSAP } from "@gsap/react";
import { useLayoutEffect, useRef } from "react";
import { Accordion } from "../components/Accordion";
import { NavBar } from "../components/Nav";
import { Footer } from "../components/Footer";
import { LogoSlider } from "../components/LogoSlider";
import BgSub from "../img/bg-sub.png";
import scrollImg from "../img/scroll.png";
import { NavLink } from "react-router-dom";
import Whatsapp from "../img/whatsapp.gif";

import project1 from "../img/image/1.png";
import iconBrand from "../icon/brand.png";
import iconSocail from "../icon/social.png";
import iconcontent from "../icon/content.png";

import iconsta from "../icon/strategic.png";
import iconinteractive from "../icon/interactive.png";
import ScrollProgressCircle from "../components/CircularProgress";

import SocaolBg from "../img/services/1.png";

import "./pages.css";
import "./text-animation.css";

import bannerImg from "../img/bg-img.png";
import star from "../icon/Star.png";

gsap.registerPlugin(ScrollTrigger);

export const Home = () => {
  const component = useRef();
  const slider = useRef();

  // Simulate progress update

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray(".panel");
      if (window.innerWidth < 768) {
        gsap.to(panels, {
          xPercent: -100 * (panels.length - 1),
          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            star: 1,
            scrub: 1,
            snap: 1 / (panels.length - 1),
            end: () => "+=" + slider.current.offsetWidth,
            markers: false,
          },
        });
      } else {
        gsap.to(panels, {
          xPercent: -100 * (panels.length - 3.322),
          ease: "none",
          scrollTrigger: {
            trigger: slider.current,
            pin: true,
            scrub: 1,
            snap: 1 / (panels.length - 1),
            end: () => "+=" + slider.current.offsetWidth,
            markers: false,
          },
        });
      }
    }, component);
    return () => ctx.revert();
  });

  useGSAP(() => {
    gsap.to(".scroll-icon", {
      rotation: 360,
      duration: 1,
      delay: 2,
      lazy: true,
      repeat: 1,
    });
  });
  const whatInfo = [
    {
      mainText: "  What exactly is Kreative Sparkz?",
    },
    {
      mainText: "  What exactly is Kreative Sparkz?",
    },
    {
      mainText: "  What exactly is Kreative Sparkz?",
    },
    {
      mainText: "  What exactly is Kreative Sparkz?",
    },
    {
      mainText: "  What exactly is Kreative Sparkz?",
    },
    {
      mainText: "  What exactly is Kreative Sparkz?",
    },
    {
      mainText: "  What exactly is Kreative Sparkz?",
    },
    {
      mainText: "  What exactly is Kreative Sparkz?",
    },
  ];
  const itemPh = [
    {
      title: "What exactly is Kreative Sparkz?",
      content:
        "Kreative Sparkz is not your typical digital marketing or web design company. We're a dedicated Sales and Branding Consultancy in Kochi committed to fueling your business's success and growth. Our journey spans over 9 years, and as a bootstrapped company, we understand the market inside out. From crafting captivating designs to strategic marketing and ultimately sealing the deal, we firmly believe that these aspects are pivotal to your triumph. That's where we step in as your trusted consultancy partner to spark the strategies for your success.",
    },
    {
      title: " Why should you choose Kreative Sparkz over other agencies?",
      content:
        " In a landscape dominated by AI and digital advancements, we recognize that relying solely on kreativity or marketing prowess isn't enough to secure success in the business world. At Kreative Sparkz, we've fused both the elements of kreativity and marketing together, forming a synergy that sets us apart. Basically we are a bunch of creative minds, tech aficionados, and marketing enthusiasts, all adept at taking your businesses forward. Our equation for success? It's the perfect blend of Kreativity + Technology + Marketing, resulting in tangible sales-driven outcomes.",
    },
    {
      title: "What are the products that Kreative Sparkz has to offer?",
      content:
        " A person who decides to set up a business has lots of hopes and dreams. We know that they put everything aside and are taking the biggest risks of their life. As on date we have realized that the market does not know what they need. Being in the market for over 9 years, we understand the actual need of a business to bloom and accordingly we have categorised 4 products that can make you stand out from the rest. Our first product is social branding, where you can take your business to the next level via Social Media. Interactive design, a complete web solution for all your website, software and application requirements.Then we have strategic marketing, where we will implement marketing strategies to maximize your sales. And finally we have Brand consulting, where you can completely revamp or start your designing, marketing and business activities from scratch..",
    },
    {
      title:
        "Can you give a guarantee for the results that you will be delivering?",
      content:
        " Over 9 years, we have worked together with all the industries in the market and we have learnt a lot and thrive from our past mistakes. With our experience and expertise we know what your audience wants and how to lure them in. So, yes this time, we do give you that assurance with our results! If it goes the other way around we will be giving you a flat 100% waiver on your next billing with us, till you achieve your results.",
    },
    {
      title: "How do you charge for your products/services?",
      content:
        " When the idea of Kreative Sparkz was forming we did not have any single penny to make it into a business. So, we do understand the pain of any nurturing or small business. That's why our pricing has been structured in a way that it wouldn't hurt the pockets of any small business owners but definitely will guarantee the best results out of your investment. If you want to get the pricing plan for your specific product, you can reach out to us : +91 98950 87313 or just drop in a pricing request.",
    },
  ];
  const customColor = "#E7E4E5";

  return (
    <div className="home ">
      <div className="absolute z-50 w-full">
        <NavBar />
      </div>
      {/* <div className="Whats">
        <a href="https://wa.me/" target="_blank">
          <img src={Whatsapp} alt="" width="80px" />
        </a>
      </div> */}

      <div className="App-sc">
        <section>
          <div className="banner">
            <div className="relative ">
              <div className="hidden h-screen overflow-hidden text-white bg-black sm:block ">
                {/* <iframe
                  src="https://player.vimeo.com/video/984121667?badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479&amp;controls=0&amp;muted=1&amp;loop=1"
                  width="1920"
                  height="1080"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                  // style={{ position: "absolute", top: 0, left: 0 }}
                  title="SHOWREEL"
                ></iframe> */}
                <div
                  style={{
                    left: "0",
                    width: "100%",
                    height: "0",
                    position: "relative",
                    paddingBottom: "56.338%",
                  }}
                >
                  <iframe
                    src="https://player.vimeo.com/video/984121667?badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479&amp;controls=0&amp;muted=1&amp;loop=1"
                    style={{
                      top: "0",
                      left: "0",
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      border: "0",
                    }}
                    allowfullscreen
                    scrolling="no"
                    allow="encrypted-media;"
                  ></iframe>
                </div>
                <div class="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center"></div>
                <div className="absolute noise-red"></div>
                <div className="absolute noise-yellow"></div>


              </div>
              <div className="block h-screen overflow-hidden text-white bg-black sm:hidden">
                <iframe
                  src="https://player.vimeo.com/video/986315349?badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479&amp;controls=0&amp;muted=1&amp;loop=1"
                  width="430 "
                  height="932"
                  frameBorder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowFullScreen
                  title="Vimeo Video"
                ></iframe>


                <div class="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center"></div>
                <div className="absolute noise-red-sm"></div>
                <div className="absolute noise-yellow-sm"></div>
              </div>

              <div
                class="flp-side-main scroll-icon z-1"
                onclick="window.location.href='#Think'"
              >
                <div class="textsec">
                  <div class="flpcircle">
                    <div class="txtcircle">
                      <img src={scrollImg} alt="" />
                    </div>
                    <div class="downarrow vert-move">
                      <i
                        class="fa-solid fa-arrow-down"
                        style={{ color: "white" }}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
              <section className="text-scrolling">
                <div className="page-animation">
                  <div class="scroll text1">
                    <div className="flex items-center">
                      <div className="sm:w-36"></div>
                      <img src={star} className="w-10 h-10 " alt="" />

                      <span> strategic marketing</span>
                      <img src={star} className="w-10 " alt="" />
                      <span> social branding </span>
                      <img src={star} className="w-10 " alt="" />
                      <span> brand consulting</span>
                      <img src={star} className="w-10 " alt="" />
                      <span style={{ backgroundColor: customColor }}>
                        social branding{" "}
                      </span>
                      <div className="sm:w-1"></div>
                    </div>
                    <div className="flex items-center">
                      <div className="sm:w-36"></div>
                      <img src={star} className="w-10 h-10 " alt="" />

                      <span> strategic marketing</span>
                      <img src={star} className="w-10 " alt="" />
                      <span> social branding </span>
                      <img src={star} className="w-10 " alt="" />
                      <span> brand consulting</span>
                      <img src={star} className="w-10 " alt="" />
                      <span style={{ backgroundColor: customColor }}>
                        social branding{" "}
                      </span>
                      <div className="sm:w-1"></div>
                    </div>
                    <div className="flex items-center">
                      <div className="sm:w-36"></div>
                      <img src={star} className="w-10 h-10 " alt="" />

                      <span> strategic marketing</span>
                      <img src={star} className="w-10 " alt="" />
                      <span> social branding </span>
                      <img src={star} className="w-10 " alt="" />
                      <span> brand consulting</span>
                      <img src={star} className="w-10 " alt="" />
                      <span style={{ backgroundColor: customColor }}>
                        social branding
                      </span>
                      <div className="sm:w-1"></div>
                    </div>
                  </div>

                  <div class="scroll text2">
                    <div className="flex items-center">
                      <div className="sm:w-36"></div>
                      <img src={star} className="w-10 h-10 " alt="" />

                      <span> strategic marketing</span>
                      <img src={star} className="w-10 " alt="" />
                      <span> social branding </span>
                      <img src={star} className="w-10 " alt="" />
                      <span> brand consulting</span>
                      <img src={star} className="w-10 " alt="" />
                      <span style={{ backgroundColor: customColor }}>
                        social branding{" "}
                      </span>
                      <div className="sm:w-1"></div>
                    </div>
                    <div className="flex items-center">
                      <div className="sm:w-36"></div>
                      <img src={star} className="w-10 h-10 " alt="" />

                      <span> strategic marketing</span>
                      <img src={star} className="w-10 " alt="" />
                      <span> social branding </span>
                      <img src={star} className="w-10 " alt="" />
                      <span> brand consulting</span>
                      <img src={star} className="w-10 " alt="" />
                      <span style={{ backgroundColor: customColor }}>
                        social branding{" "}
                      </span>
                      <div className="sm:w-1"></div>
                    </div>
                    <div className="flex items-center">
                      <div className="sm:w-36"></div>
                      <img src={star} className="w-10 h-10 " alt="" />

                      <span> strategic marketing</span>
                      <img src={star} className="w-10 " alt="" />
                      <span> social branding </span>
                      <img src={star} className="w-10 " alt="" />
                      <span> brand consulting</span>
                      <img src={star} className="w-10 " alt="" />
                      <span style={{ backgroundColor: customColor }}>
                        social branding
                      </span>
                      <div className="sm:w-1"></div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div className="relative ">
              {/* <img className="bg-img" src={bannerImg} alt="" /> */}
              {/* <div className="scroll-icon">
                <img src={scrollImg} alt="" />
              </div> */}
            </div>
          </div>
        </section>
        <section className=" main-section think-bg" id="Think">
          <div className="short-note">
            <h3 className="big-text">Think Business Speak Digital</h3>
            <p>
              At Kreative Sparkz we don't just think business, we envision
              success in every pixel, click, and byte. In a world woven with
              virtual threads, we master the art of storytelling through digital
              space, crafting narratives that captivate hearts and minds.
            </p>
            <p>
              Together lets get on a journey where technology meets creativity,
              where ideas converge with strategy, and where your business
              thrives and reaches unparalleled heights.
            </p>
          </div>
          <div className="flex justify-center text-center lg:mt-10">
            <NavLink
              to="/services"
              className="flex justify-center btn btn-primary"
            >
              <span className="z-20">Explore More</span>
            </NavLink>
          </div>
          <div></div>
        </section>
        {/* <img className=" h-96 sm:h-4/5" src={BgSub} alt="" /> */}

        <section className="flex bg-black">
          <div className="bg-black -z-0" ref={component}>
            {/* <div className="firstContainer">
            <h1>Testing horizontal scrolling w/ three sections</h1>
            <h2>First Container</h2>
          </div> */}
            <div ref={slider} className="container sm:flex-col">
              <div className="description panel">
                <div className="flex card-services card-services-1">
                  <div className="px-2 pt-3">
                    <div className="inline-block">
                      <div className="p-6 border rounded-full">
                        <img src={iconinteractive} alt="" />
                      </div>
                    </div>
                    <h1 className="card-head">interactive design</h1>
                    <p>
                      The first thing your audience does is google about you. Be
                      at the top of the search and establish an online presence
                      through building websites, softwares and applications.
                      Expand your reach, engage with customers and build a
                      reputable brand with our digital pathways.
                    </p>
                    <div className="mt-5 ">
                      <div>
                        {/* <button className="flex justify-center w-24 py-3 text-center border rounded-full sm:p-2 btn-round">
                          <span className="z-0">
                            <svg
                              className="z-0"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="sm:w-6 sm:h-6 w-4 h-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              />
                            </svg>
                          </span>
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="card-services card-services-2">
                  <div className="px-2 pt-3">
                    <div className="inline-block">
                      <div className="p-6 border rounded-full">
                        <img src={iconsta} alt="" />
                      </div>
                    </div>
                    <h1 className="card-head">strategic marketing</h1>
                    <p>
                      Through strategic marketing we navigate the world of
                      Digital Marketing to reach your target audience and
                      achieve your marketing goals. With an effective and
                      customized marketing strategy we do not just give you
                      random leads but the most potential ones to close in
                      sales!
                    </p>
                    <div className="mt-5 ">
                      <div>
                        {/* <button className="flex justify-center w-24 py-3 text-center border rounded-full sm:p-2 btn-round">
                          <span className="z-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="sm:w-6 sm:h-6 w-4 h-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              />
                            </svg>
                          </span>
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="card-services card-services-3">
                  <div className="px-2 pt-3">
                    <div className="inline-block">
                      <div className="p-6 border rounded-full">
                        <img src={iconBrand} alt="" />
                      </div>
                    </div>
                    <h1 className="card-head">brand consulting</h1>
                    <p>
                      Whether you're in search of a fresh launch for your
                      business venture or in the process to initiate a complete
                      revamp, Brand consulting is your go to. From building
                      websites to running ads, we offer a complete solution to
                      build your brand from scratch and take it to the next
                      level.
                    </p>
                    <div className="mt-5 ">
                      <div>
                        {/* <button className="flex justify-center w-24 py-3 text-center border rounded-full sm:p-2 btn-round">
                          <span className="z-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="sm:w-6 sm:h-6 w-4 h-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              />
                            </svg>
                          </span>
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="card-services card-services-4">
                  <div className="px-2 pt-3">
                    <div className="inline-block">
                      <div className="p-6 border rounded-full">
                        <img src={iconSocail} alt="" />
                      </div>
                    </div>
                    <h1 className="card-head">social branding</h1>
                    <p>
                      The first thing your audience does is google about you. Be
                      at the top of the search and establish an online presence
                      through building websites, softwares and applications.
                      Expand your reach, engage with customers and build a
                      reputable brand with our digital pathways.
                    </p>
                    <div className="mt-5 ">
                      <div>
                        {/* <button className="flex justify-center w-24 py-3 text-center border rounded-full sm:p-2 btn-round">
                          <span className="z-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="sm:w-6 sm:h-6 w-4 h-4"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m8.25 4.5 7.5 7.5-7.5 7.5"
                              />
                            </svg>
                          </span>
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel">
                <div className="card-services card-services-5">
                  <div className="px-2 pt-3">
                    <div className="inline-block">
                      <div className="p-6 border rounded-full">
                        <img src={iconcontent} alt="" />
                      </div>
                    </div>
                    <h1 className="card-head">content writing</h1>
                    <p>
                      Let words captivate your audience and drive them to take
                      the next step. We offer clear, concise and compelling
                      content writing solutions for blogs, websites, social
                      media posts and product descriptions that sparks interest,
                      ignites engagement and boost search engine rankings of
                      your brand online.
                    </p>
                    <div className="mt-5 ">
                      <div></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="lastContainer">Last Container</div> */}
          </div>
        </section>
        <section>
          <div className="grid grid-cols-2 sm:grid-cols-4">
            {/* {whatInfo?.map((info, index) => (
              <div className="card-what zoom-in">
                <div className="z-40 flex items-center justify-between w-full gap-1 align-middle sm:gap-4">
                  <h5 className="text-base font-semibold sm:text-xl ">
                    {info?.mainText}
                  </h5>
                  <div>
                    <button className="flex p-2 border rounded-full btn-round">
                      <span className="z-0 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="sm:w-6 sm:h-6 w-4 h-4"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            ))} */}
          </div>
        </section>
        <section className="bg-white main-section ">
          <h2 className="mb-4 text-3xl font-bold text-center sm:text-5xl sm:mb-20">
            partners in success
          </h2>
          <LogoSlider />
        </section>
        <section className="bg-white main-section faq">
          <div className="grid gap-6 sm:grid-cols-2">
            <div>
              <h2 className="mb-2 text-4xl font-bold sm:mb-5 sm:text-5xl ">
                Information Hub
              </h2>
              <p className="text-base leading-6 md:w-3/5 sm:text-2x4">
                At Kreative Sparkz we don't just think business, we envision
                success in every pixel.
              </p>
            </div>
            <div>
              <Accordion item={itemPh} />
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
};
