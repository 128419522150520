import React from 'react';
import './CustomCursor.css';

const CustomCursor = () => {
  const [position, setPosition] = React.useState({ x: 0, y: 0 });
  const [hovered, setHovered] = React.useState(false); // Track if cursor is hovering
  const [hoverText, setHoverText] = React.useState(''); // Track hovered text

  const updatePosition = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  React.useEffect(() => {
    document.addEventListener('mousemove', updatePosition);
    return () => {
      document.removeEventListener('mousemove', updatePosition);
    };
  }, []);

  const handleHover = (text) => {
    setHovered(true);
    setHoverText(text);
  };

  const handleUnhover = () => {
    setHovered(false);
    setHoverText('');
  };

  return (
    <div className={`custom-cursor ${hovered ? 'hovered' : ''}`} style={{ left: position.x, top: position.y }}>
      <div className="circle"></div>
      {hoverText && <div className="hover-text">{hoverText}</div>}
    </div>
  );
};

export default CustomCursor;
