import React from "react";
import "./logslider.css";
import brandLogoOne from "../icon/brand-logo/1.png";
import brandLogoTwo from "../icon/brand-logo/2.png";
import brandLogoThree from "../icon/brand-logo/3.png";
import brandLogoFour from "../icon/brand-logo/4.png";
import brandLogoFive from "../icon/brand-logo/5.png";
import brandLogoSix from "../icon/brand-logo/6.png";
import brandLogoSeven from "../icon/brand-logo/7.png";
import brandLogoEight from "../icon/brand-logo/8.png";
import brandLogoNine from "../icon/brand-logo/9.png";
import brandLogoTen from "../icon/brand-logo/10.png";
import brandLogoEleven from "../icon/brand-logo/11.png";
import brandLogoTwelve from "../icon/brand-logo/12.png";
import brandLogoThirteen from "../icon/brand-logo/13.png";
import brandLogoFourteen from "../icon/brand-logo/14.png";
import brandLogoFifteen from "../icon/brand-logo/15.png";
import brandLogoSixteen from "../icon/brand-logo/16.png";
import brandLogoSeventeen from "../icon/brand-logo/17.png";
import brandLogoEighteen from "../icon/brand-logo/18.png";
import brandLogoNineteen from "../icon/brand-logo/19.png";
import brandLogotTwenty from "../icon/brand-logo/20.png";
import brandLogotTwentyOne from "../icon/brand-logo/21.png";

import brandLogo from "../icon/brand-logo/13.png";

export const LogoSlider = () => {
  return (
    <div>
      <div className="slider">
        <div className="slide-track">
          <div className="slide">
            <img src={brandLogoOne} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwo} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFour} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFive} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSix} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoEight} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoNine} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyOne} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwo} alt="" />
          </div>





          <div className="slide">
            <img src={brandLogoEleven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwelve} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThirteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFourteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFifteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSixteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeventeen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoEighteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoNineteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwenty} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThirteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwelve} alt="" />
          </div>
        </div>
      </div>
      <div className="mt-0 sm:mt-8 slider">
        <div className="slide-track2">
          <div className="slide">
            <img src={brandLogoEleven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwelve} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThirteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFourteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFifteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSixteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeventeen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoEighteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoNineteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwenty} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThirteen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwelve} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoOne} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwo} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFour} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoFive} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSix} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoSeven} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoEight} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoNine} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTen} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogotTwentyOne} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoThree} alt="" />
          </div>
          <div className="slide">
            <img src={brandLogoTwo} alt="" />
          </div>


        </div>
      </div>
    </div>
  );
};
