import React, { useEffect, useRef } from "react";
import { NavBar } from "../components/Nav";
import { Footer } from "../components/Footer";
import { NavLink } from "react-router-dom";
import Whatsapp from "../img/whatsapp.gif";


import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import arrowIcon from "../img/icon/arrow-up-sharp-dr.png";
import workOne from "../img/page/Portfolio/work-img/1.png";
import workTwo from "../img/page/Portfolio/work-img/2.png";
import workThree from "../img/page/Portfolio/work-img/3.png";
import workFour from "../img/page/Portfolio/work-img/4.png";
import workFive from "../img/page/Portfolio/work-img/5.png";
import workSix from "../img/page/Portfolio/work-img/6.png";

import ImageGallery from '../components/filterImage';


export const Portfolio = () => {
  // const card1Ref = useRef(null);
  // const card2Ref = useRef(null);
  // const card3Ref = useRef(null);
  const sectionsRef = useRef([]);

  




  // useEffect(() => {
  //   sectionsRef.current.forEach((section, index) => {
  //     gsap.fromTo(section, 
  //       { opacity: 0, y: 50 }, 
  //       {
  //         opacity: 1,
  //         y: 0,
  //         duration: 1,
  //         scrollTrigger: {
  //           trigger: section,
  //           start: 'top 80%',
  //           end: 'bottom 20%',
  //           scrub: true,
  //         },
  //       });
  //   });
  // }, []);
  return (
    <div className="portfolio">
      <div className="sub-banner">
        <div className="absolute z-50 w-full">
          <NavBar />
        </div>
        <div className="text-body">
          <h2 className="sub-page-head">our portfolio</h2>
        </div>
        <div className="px-4 banner-botton md:px-20">
          <NavLink to="">
            <i class="fa-solid fa-arrow-left"></i>  BACK TO HOME
          </NavLink>
        </div>
      </div>
      {/* <div className="pb-3 main-section">
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s
          </p>
        </div>
      </div> */}
      <div className="Whats">
        <a href="https://wa.me/" target="_blank">
          <img src={Whatsapp} alt="" width="80px" />
        </a>
      </div>
      <section className="portfolio-about">
      <div className="h-screen sm:py-10 main-section dark-bg-g section-bg">
          <div className="grid grid-cols-1 sm:grid-cols-3">
            <div className="pt-3 sm:col-span-1 sm:pt-7">
              <span className="white-sub-head">
              Lambegus abroad consultancy              </span>
              <p className="pt-3 text-white ">
              This website for Lambegus Abroad Consultancy is created by utilizing the power of React.Js and  Tailwind CSS for a seamless front end experience. The design elements are meticulously crafted with Figma Design tool, ensuring a combination of performance and visually appealing user experience that is engaging and informative. 
              </p>
            </div>
            <div className="sm:col-span-2">
              <div className="highlight-work-two"></div>
            </div>
          </div>
        </div>
        <div className="h-screen sm:py-10 main-section white-bg-g section-bg">
          <div className="grid grid-cols-1 sm:grid-cols-3">
            <div className="pt-3 sm:col-span-1 sm:pt-7">
              <span className=" dark-sub-head">
              Mec robotics - reinventing robotics              </span>
              <p className="pt-3">
              Crafted with a combination of HTML and Bootstrap, this website delivers a visually stunning user experience along with smooth animations. Built with cutting edge design tools, the interface fosters an engaging user experience. 
              </p>
            </div>
            <div className="sm:col-span-2">
              <div className="highlight-work-one"></div>
            </div>
          </div>
        </div>
       
        <div className="h-screen sm:py-10 main-section white-bg-g section-bg">
          <div className="grid grid-cols-1 sm:grid-cols-3">
            <div className="pt-3 sm:col-span-1 sm:pt-7">
              <span className="dark-sub-head">
              Stylo Interiors
              </span>
              <p className="pt-3">
              Built with state-of-the-art technologies like React.js and Tailwind CSS, this website for Stylo Interiors delivers an intuitive and captivating user journey. Every element is meticulously designed and crafted, ensuring an innovative and engaging user experience.
              </p>
            </div>
            <div className="sm:col-span-2">
              <div className="highlight-work-three"></div>
            </div>
          </div>
        </div>
        
      </section>
      <section className="portfolio-images">
        <div className="sm:py-10 main-section">
{/*           
          <div>
            <button
              type="button"
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200"
            >
              All
            </button>
            <button
              type="button"
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200"
            >
              Branding
            </button>
            <button
              type="button"
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200"
            >
              App
            </button>
            <button
              type="button"
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200"
            >
              Web
            </button>
            <button
              type="button"
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200"
            >
              Photography
            </button>
            <button
              type="button"
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200"
            >
              Video Production
            </button>
            <button
              type="button"
              class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-full border border-gray-200"
            >
              Ad Campaigns
            </button>
          </div> */}
          {/* <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workOne} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workTwo} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workThree} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workFour} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workFive} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workSix} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workOne} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workTwo} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workThree} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
            <div className="product-card">
              <div className="">
                <img className="product-img" src={workFour} alt="" />
                <div className="flex items-center justify-between">
                  <h6 className="text-xl ">lorem ipsum</h6>
                  <img src={arrowIcon} alt="" />
                </div>
              </div>
            </div>
          </div> */}
                <ImageGallery />

        </div>
      </section>
      <Footer />
    </div>
  );
};
