import React from "react";
import { NavBar } from "../components/Nav";
import { Accordion } from "../components/Accordion";
import { Footer } from "../components/Footer";
import { NavLink } from "react-router-dom";
import Whatsapp from "../img/whatsapp.gif";

export const FAQs = () => {
  const itemPh = [
    {
      title: "What exactly is Kreative Sparkz?",
      content:
        " Kreative Sparkz is not your typical digital marketing or web design company. We're a 	dedicated Sales and Branding Consultancy in Kochi committed to fueling your business's success and growth. Our journey spans over 9 years, and as a bootstrapped company, we understand the market inside out. From crafting captivating designs to strategic marketing and ultimately sealing the deal, we firmly believe that these aspects are pivotal to your triumph. That's where we step in as your trusted consultancy partner to spark the strategies for your success.",
    },
    {
      title: " Why should you choose Kreative Sparkz over other agencies?",
      content:
        " In a landscape dominated by AI and digital advancements, we recognize that relying solely on kreativity or marketing prowess isn't enough to secure success in the business world. At Kreative Sparkz, we've fused both the elements of kreativity and marketing together, forming a synergy that sets us apart. Basically we are a bunch of creative minds, tech aficionados, and marketing enthusiasts, all adept at taking your businesses forward. Our equation for success? It's the perfect blend of Kreativity + Technology + Marketing, resulting in tangible sales-driven outcomes.",
    },
    {
      title: "What are the products that Kreative Sparkz has to offer?",
      content:
        " A person who decides to set up a business has lots of hopes and dreams. We know that they put everything aside and are taking the biggest risks of their life. As on date we have realized that the market does not know what they need. Being in the market for over 9 years, we understand the actual need of a business to bloom and accordingly we have categorised 4 products that can make you stand out from the rest. Our first product is social branding, where you can take your business to the next level via Social Media. Interactive design, a complete web solution for all your website, software and application requirements. Then we have strategic marketing, where we will implement marketing strategies to maximize your sales. And finally we have Brand consulting, where you can completely revamp or start your designing, marketing and business activities from scratch.",
    },
    {
      title:
        "Can you give a guarantee for the results that you will be delivering?",
      content:
        "Kreative Sparkz is not your typical digital marketing or web design company. We're a dedicated Sales and Branding Consultancy in Kochi committed to fueling your business's success and growth. Our journey spans over 9 years, and as a bootstrapped company, we understand the market inside out. From crafting captivating designs to strategic marketing and ultimately sealing the deal, we firmly believe that these aspects are pivotal to your triumph. That's where we step in as your trusted consultancy partner to spark the strategies for your success.",
    },
    {
      title:
        " Can you give a guarantee for the results that you will be delivering?",
      content:
        " Over 9 years, we have worked together with all the industries in the market and we have learnt a lot and thrive from our past mistakes. With our experience and expertise we know what your audience wants and how to lure them in. So, yes this time, we do give you that assurance with our results! If it goes the other way around we will be giving you a flat 100% waiver on your next billing with us, till you achieve your results.",
    },
    {
      title: "How do you charge for your products/services?",
      content:
        " When the idea of Kreative Sparkz was forming we did not have any single penny to make it into a business. So, we do understand the pain of any nurturing or small business. That's why our pricing has been structured in a way that it wouldn't hurt the pockets of any small business owners but definitely will guarantee the best results out of your investment. If you want to get the pricing plan for your specific product, you can reach out to us : +91 98950 87313 or just drop in a pricing request..",
    },
  ];
  return (
    <div>
      <div className="relative information ">
        <div className="sub-banner">
          <div className="absolute z-50 w-full">
            <NavBar />
          </div>
          {/* <div className="Whats">
            <a href="https://wa.me/" target="_blank">
              <img src={Whatsapp} alt="" width="80px" />
            </a>
          </div> */}
          <div className="text-body">
            <h2 className="sub-page-head">Information Hub</h2>
          </div>
          <div className="px-4 banner-botton md:px-20">
            <NavLink to="/">
              <i class="fa-solid fa-arrow-left"></i>  BACK TO HOME
            </NavLink>
          </div>
        </div>
        <section className="">
          <div className="main-section information-bg">
            <div className="grid gap-4 sm:grid-cols-3 grid-col-1 ">
              <div className="h-32 sm:sticky pt-9 sm:col-span-1 sm:top-3">
                <h4 className="mb-2 sm:mb-6 white-sub-head">FAQs</h4>
                <p className="text-white ">
                  orem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s,
                </p>
              </div>
              <div className="mt-8 sm:mt-0 sm:col-span-2">
                <div>
                  <Accordion item={itemPh} style={"white"} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};
