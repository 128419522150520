import React from "react";
import { NavBar } from "../components/Nav";
import { Footer } from "../components/Footer";
import { NavLink } from "react-router-dom";

import smallIcon from "../img/icon/small-icon.png";
import FounderImg from "../img/photo/Founder.png";
import dotsBox from "../img/dots-box.png";
import signature from "../img/photo/signature.png";
import bgFounder from "../img/founder-bg.png";
import Slider from "react-slick";
import blogOne from "../img/blog/1.png";
import blogTwo from "../img/blog/2.png";
import blogThree from "../img/blog/3.png";
import Whatsapp from "../img/whatsapp.gif";

import iconArrow from "../img/icon/ion_arrow-up-sharp.png";

const employeeDetails = [
  {
    name: "athul",
    url: require("../img/photo/no-photo.png"),
    designation: "UI/UX designer ",
  },
  {
    name: "sample",
    url: require("../img/photo/photo.png"),
    designation: "UI/UX designer ",
  },
  {
    name: "sample2",
    url: require("../img/photo/photo.png"),
    designation: "UI/UX designer ",
  },
  {
    name: "sample3",
    url: require("../img/photo/photo.png"),
    designation: "Ui designer",
  },
  {
    name: "sample4",
    url: require("../img/photo/no-photo.png"),
    designation: "UI/UX designer ",
  },
  {
    name: "sample5",
    url: require("../img/photo/no-photo.png"),
    designation: "UI/UX designer ",
  },
];
const SliderSettings = {
  dots: false,
  slidesToShow: 3,
  slidesToScroll: 1,
  // autoplay: true,
  autoplaySpeed: 2000,
  dots: true,
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768, // tablet breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },

    {
      breakpoint: 480, // mobile breakpoint
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const OurKreators = () => {
  return (
    <div className="our-kreators">
      <div className="sub-banner">
        <div className="absolute z-50 w-full">
          <NavBar />
        </div>
        <div className="text-body">
          <h2 className="sub-page-head">Our Kreators</h2>
        </div>
        <div className="px-4 banner-botton md:px-20">
          <NavLink to="/" href="#">
            {" "}
            <i class="fa-solid fa-arrow-left"></i> BACK TO HOME{" "}
          </NavLink>
        </div>
      </div>
      <div className="process">
        <div className="main-section">
          <div className="grid items-end grid-cols-1 sm:grid-cols-2">
            <div>
              <small className="small-title">The Process</small>
              <div className="sm:flex sm:flex-col ">
                <span className="white-sub-head">
                  Where Passion & Imagination meet,
                </span>
                <span>
                  <span className="primary-sub-head">
                    Execution and Manifestation
                  </span>
                  <span className="white-sub-head">.</span>
                </span>
              </div>
            </div>
            <div>
              <div className="flex gap-3 mt-6 sm:mt-0">
                <div>
                  <img src={smallIcon} alt="" className="w-24 sm:w-14" />
                </div>
                <p>
                  Establish a strong online presence by building interactive
                  websites, software, and web applications. Broaden your reach,
                  engage with more customers, and escalate your brand to greater
                  heights with our digital expertise.
                </p>
              </div>
              <div className="flex gap-3 mt-8 sm:mt-20">
                <div>
                  <img src={smallIcon} alt="" className="w-24 sm:w-14" />
                </div>
                <p>
                  Accomplish marketing goals and reach your target audience. Our
                  strategies are not just to generate random leads but the most
                  potential ones that can be converted into sales. Make them
                  come for more.
                </p>
              </div>
              <div className="flex gap-3 mt-8 sm:mt-20">
                <div>
                  <img src={smallIcon} alt="" className="w-24 sm:w-14" />
                </div>
                <p>
                  From building websites to running digital ads, we promise to
                  give your brand a total revamp with our all- inclusive
                  solutions and skyrocket it to success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="Whats">
        <a href="https://wa.me/" target="_blank">
          <img src={Whatsapp} alt="" width="80px" />
        </a>
      </div> */}
      <div className="relative grid sm:grid-cols-2">
        <div>
          <img className="object-cover h-full" src={FounderImg} alt="" />
        </div>
        <div className="absolute top-10 right-[46%] hidden sm:block ">
          <img className="w-36" src={dotsBox} alt="" />
        </div>
        <div className="main-section bg-founder">
          <small className=" small-title">About Agency</small>
          <h3 className="text-[#272727] sm:text-4xl text-2xl font-bold my-1 sm:my-3">
            Lorem ipsum dolor sit
          </h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book.
          </p>
          <img className="mt-3" src={signature} alt="" />
          <small className=" mt-3 block font-[600] leading-[0.5]">
            Founder Name
          </small>
          <small className="font-normal ">Founder</small>
        </div>
      </div>
      {/* <div className="main-section group-bg">
        <div>
          <div className="text-center sm:w-3/6 sm:m-auto">
            <h4 className="mb-2 sm:mb-6 white-sub-head">
              meet the people who will be working with you
            </h4>
          </div>

          <p className="text-center text-white sm:w-4/5 sm:m-auto">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make
          </p>
        </div>
        <div>
          <div className="flex items-center gap-3 mt-8 sm:gap-11 sm:mt-14">
            <h6 className="text-lg text-white whitespace-nowrap">
              Technical Team
            </h6>
            <div className="white-line"></div>
          </div>
          <div className="grid grid-cols-2 mt-4 sm:mt-6 sm:grid-cols-4">
            {employeeDetails.map((value) => (
              <div className="text-white worker-card" key={value.name}>
                <img src={value.url} alt="" />
                <div></div>

                <div className="worker-info">
                  <h5>{value.name}</h5>
                  <h6>{value.designation}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <div className="flex items-center gap-3 mt-8 sm:gap-11 sm:mt-14">
            <h6 className="text-lg text-white whitespace-nowrap">
              Technical Team
            </h6>
            <div className="white-line"></div>
          </div>
          <div className="grid grid-cols-2 mt-4 sm:mt-6 sm:grid-cols-4">
            {employeeDetails.map((value) => (
              <div className="text-white worker-card" key={value.name}>
                <img src={value.url} alt="" />
                <div></div>

                <div className="worker-info">
                  <h5>{value.name}</h5>
                  <h6>{value.designation}</h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
      {/* <div className="main-section blog-bg">
        <div>
          <div className="text-center sm:w-3/6 sm:m-auto">
            <h4 className="mb-2 sm:mb-6 dark-sub-head">our blogs</h4>
          </div>

          <p className="text-center sm:w-4/5 sm:m-auto">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make
          </p>
          <div className="mt-8 sm:gap-11 sm:mt-14">
            <Slider {...SliderSettings}>
              <div className="px-2">
                <div>
                  <img src={blogOne} alt="" />
                </div>
                <div className="blog-info">
                  <div>
                    <p>
                      Lorem ipsum dolor sitLorem ipsum dolor sitipsum dolor sit
                    </p>
                    <div className="text-end">
                      <small>22 March 2024</small>
                    </div>
                  </div>
                  <div className="read-more">
                    <div className="flex items-center ">
                      <h5>Read More</h5>
                      <a href="#">
                        <img src={iconArrow} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2">
                <div>
                  <img src={blogTwo} alt="" />
                </div>
                <div className="blog-info">
                  <div>
                    <p>
                      Lorem ipsum dolor sitLorem ipsum dolor sitipsum dolor sit
                    </p>
                    <div className="text-end">
                      <small>22 March 2024</small>
                    </div>
                  </div>
                  <div className="read-more">
                    <div className="flex items-center ">
                      <h5>Read More</h5>
                      <a href="#">
                        <img src={iconArrow} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2">
                <div>
                  <img src={blogThree} alt="" />
                </div>
                <div className="blog-info">
                  <div>
                    <p>
                      Lorem ipsum dolor sitLorem ipsum dolor sitipsum dolor sit
                    </p>
                    <div className="text-end">
                      <small>22 March 2024</small>
                    </div>
                  </div>
                  <div className="read-more">
                    <div className="flex items-center ">
                      <h5>Read More</h5>
                      <a href="#">
                        <img src={iconArrow} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-2">
                <div>
                  <img src={blogOne} alt="" />
                </div>
                <div className="blog-info">
                  <div>
                    <p>
                      Lorem ipsum dolor sitLorem ipsum dolor sitipsum dolor sit
                    </p>
                    <div className="text-end">
                      <small>22 March 2024</small>
                    </div>
                  </div>
                  <div className="read-more">
                    <div className="flex items-center ">
                      <h5>Read More</h5>
                      <a href="#">
                        <img src={iconArrow} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div> */}
      <Footer />
    </div>
  );
};
