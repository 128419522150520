import React, { useState, useEffect } from "react";

export const AccordionService = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const rotateArrow = (index) => {
    return activeIndex === index ? "rotate" : "";
  };
  return (
    <div>
      <div
        className={`accordion-fq ${
          props.style === "white" ? "text-white" : ""
        }`}
      >
        {props.item.map((itemAcc, index) => (
          <div key={index} className="accordion-item">
            <div
              className={`accordion-header-fq flex justify-between items-center ${
                activeIndex === index ? "accordion-active" : ""
              }`}
              onClick={() => handleClick(index)}
            >
              <h3 className="mt-2 mb-3 text-lg font-bold leading-6 cursor-pointer sm:text-xl">
                {itemAcc.title}
              </h3>
              {/* <span
                className={`ml-2 transition-transform duration-200 transform   ${rotateArrow(
                  index
                )}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="12" y1="5" x2="12" y2="19"></line>
                  <line x1="5" y1="12" x2="19" y2="12"></line>
                </svg>
              </span> */}

              {activeIndex === index ? (
                <span  className={`ml-2 transition-transform duration-200 transform   ${rotateArrow(
                  index
                )}`}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>{" "}
                </span>
              ) : (
                <span
                
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </span>
              )}
            </div>
            {activeIndex === index && (
              <div className="accordion-content">
                <p className="mb-3 leading-6 sm:text-lg">{itemAcc.content}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
