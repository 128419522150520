// ImageGallery.js
import React, { useState } from 'react';
import ChipButtons from './ChipButtons';
import arrowIcon from "../img/icon/arrow-up-sharp-dr.png";


import workOne from "../img/page/Portfolio/work-img/1.png";
import workTwo from "../img/page/Portfolio/work-img/2.png";
import workThree from "../img/page/Portfolio/work-img/3.png";
import workFour from "../img/page/Portfolio/work-img/4.png";
import workFive from "../img/page/Portfolio/work-img/5.png";
import workSix from "../img/page/Portfolio/work-img/6.png";
import workSeven from "../img/page/Portfolio/work-img/7.png";
import workEight from "../img/page/Portfolio/work-img/8.png";
import HairPoster from "../img/page/Portfolio/hair.png"
import transcendPoster from  "../img/page/Portfolio/transcend.png"
import Caremarkposter  from  "../img/page/Portfolio/Caremark.png"
import video  from  "../img/page/Portfolio/inAd.png"
import video2  from  "../img/page/Portfolio/inAd2.png"
import MotionGraphics1  from  "../img/page/Portfolio/Gm1.png"
import MotionGraphics2  from  "../img/page/Portfolio/gm2.png"
import animation1  from  "../img/page/Portfolio/2d.png"
import animation2  from  "../img/page/Portfolio/2d1.png"










const images = [
  { label: 'Technology', src: workOne , subText:"text lor" },
  { label: 'Technology', src: workTwo ,subText:"lorem ipsum"},
  { label: 'Technology', src: workThree ,subText:"lorem ipsum"},
  { label: 'Technology', src: workFour,subText:"lorem ipsum" },
  { label: 'Technology', src: workFive,subText:"lorem ipsum" },
  { label: 'Technology', src: workSix,subText:"lorem ipsum" },
  { label: 'Technology', src: workSeven,subText:"lorem ipsum" },
  { label: 'Technology', src: workEight,subText:"lorem ipsum" },
  { label: 'Ad Poster', src: HairPoster,subText:"lorem ipsum" },
  { label: 'Ad Poster', src: transcendPoster,subText:"lorem ipsum" },
  { label: 'Ad Poster', src: Caremarkposter,subText:"lorem ipsum" },
  { label: 'video', src: video,subText:"lorem ipsum" },
  { label: 'video', src: video2,subText:"lorem ipsum" },
  { label: 'MotionGraphics', src: MotionGraphics1,subText:"lorem ipsum" },
  { label: 'MotionGraphics', src: MotionGraphics2,subText:"lorem ipsum" },
  { label: '2D Animation', src: animation1,subText:"lorem ipsum" },
  { label: '2D Animation', src: animation2,subText:"lorem ipsum" },


  



  







];

const ImageGallery = () => {
  const [sortedImages, setSortedImages] = useState(images);
  const [selectedLabel, setSelectedLabel] = useState('All');

  const labels = ['All', ...new Set(images.map((image) => image.label))];

  const sortImages = (label) => {
    setSelectedLabel(label);
    if (label === 'All') {
      setSortedImages(images);
    } else {
      const filteredImages = images.filter((image) => image.label === label);
      setSortedImages(filteredImages);
    }
  };

  return (
    <div>
      <ChipButtons labels={labels} sortImages={sortImages} selectedLabel={selectedLabel} />
      <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
                {sortedImages.map((image, index) => (


<div key={index}  className="product-card">
<div className="">
  <img className="product-img" src={image.src} alt={image.label} />
  {/* <div className="flex items-center justify-between">
    <h6 className="text-xl ">{image.subText}</h6>
    <img src={arrowIcon} alt="" />
  </div> */}
</div>
</div>




        ))}
      </div>
    </div>
  );
};

export default ImageGallery;
