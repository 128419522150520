import React, { useEffect, useState } from 'react';
import './ScrollProgressCircle.css';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const ScrollProgressCircle = () => {
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const fullHeight = document.body.scrollHeight;

    const progress = (scrollPosition / (fullHeight - windowHeight)) * 100;
    setScrollProgress(progress);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="fixed bottom-0 right-0 m-4 circle-loader">
      <div className="relative w-20 h-20">
        <div className="absolute inset-0 flex items-center justify-center font-bold text-white" style={{color:'#BE0E2C'}}>
          {Math.round(scrollProgress)}%
        </div>
        {/* <svg
          className="absolute inset-0 w-full h-full"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className="text-red-500 stroke-current"
            cx="50"
            cy="50"
            r="45"
            strokeWidth="8"
            fill="none"
          />
          <circle
            className="text-white stroke-current"
            cx="50"
            cy="50"
            r="45"
            strokeWidth="8"
            fill="none"
            strokeDasharray={`${scrollProgress}, 100`}
          />
        </svg> */}

        <CircularProgressbar minValue={0} value={scrollProgress} maxValue={100} strokeWidth={8}
        
        styles={{
            // Customize the root svg element
            root: {},
            // Customize the path, i.e. the "completed progress"
            path: {
              // Path color
              stroke: '#BE0E2C',
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'butt',
              // Customize transition animation
              transition: 'stroke-dashoffset 0.5s ease 0s',
              // Rotate the path
              transform: 'rotate(0.25turn)',
              transformOrigin: 'center center',
            },
            // Customize the circle behind the path, i.e. the "total progress"
            trail: {
              // Trail color
              stroke: '#E4E2E3',
              // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
              strokeLinecap: 'round',
              // Rotate the trail
              transform: 'rotate(0.25turn)',
              transformOrigin: 'center center',
            },
            // Customize the text
            text: {
              // Text color
              fill: '#BE0E2C',
              // Text size
              fontSize: '16px',
            },
            // Customize background - only used when the `background` prop is true
            background: {
              fill: '#3e98c7',
            },
          }}
        />
      </div>
    </div>
  );
};

export default ScrollProgressCircle;
