import React from "react";
import { NavBar } from "../components/Nav";
import { AccordionService } from "../components/AccordionService";
import { Footer } from "../components/Footer";

import SideImg from "../img/page/servies/side-img.png";
import SmallDot from "../img/page/servies/small-dot.png";
import Video from "../img/page/servies/video.png";
import Arrow from "../icon/arrow-up-right.png";
import { NavLink } from "react-router-dom";
import Whatsapp from "../img/whatsapp.gif";

export const Services = () => {
  const itemPh = [
    {
      title: "Social Branding",
      content:
        "Give the stalkers the visual treat that they seek and build your social media pages. From captivating posters to engaging videos, turn your ideas into living, breathing digital art. Say hello to digital assets that embody your brand's story and connect with your audience.",
    },
    {
      title: " Interactive Design",
      content:
        "The first thing your audience does is google about you. Be at the top of the search and establish an online presence through building websites, softwares and applications. Expand your reach, engage with customers and build a reputable brand with our digital pathways. ",
    },
    {
      title: "Strategic Marketing",
      content:
        "Through strategic marketing we navigate the world of Digital Marketing to reach your target audience and achieve your marketing goals. With an effective and customized marketing strategy we do not just give you random leads but the most potential ones to close in sales!",
    },
    {
      title: "Brand Consulting",
      content:
        "Whether you're in search of a fresh launch for your business venture or in the process to initiate a complete revamp, Brand consulting is your go to. From building websites to running ads, we offer a complete solution to build your brand from scratch and take it to the next level.",
    },
    {
      title: "Content Writing",
      content:
"In Social Media, Content is always the King. We craft captivating and informative text that educates entertains and persuades audiences to take action, driving engagement and conversions and ultimately elevating your brand to the next level."    },
  ];
  return (
    <div>
      <div className="servies">
        <div className="sub-banner">
          <div className="absolute z-50 w-full">
            <NavBar />
          </div>
          <div className="text-body">
            <h2 className="sub-page-head">Services</h2>
          </div>
          <div className="px-4 banner-botton md:px-20">
            <NavLink to="/">
              <i class="fa-solid fa-arrow-left"></i>  BACK TO HOME
            </NavLink>
          </div>
        </div>
        {/* <div className="Whats">
          <a href="https://wa.me/" target="_blank">
            <img src={Whatsapp} alt="" width="80px" />
          </a>
        </div> */}
        <div className="relative grid grid-cols-1 sm:grid-cols-2">
          <div className="sm:pr-6 main-section ">
            <span className="dark-sub-head">Telling Brand Stories with </span>
            <span className="primary-sub-head"> Purpose and Passion.</span>
            <p className="mt-3">
              Kreative Sparkz Ad Media Private Limited, is a brand agency that
              understands the power of branding and storytelling. We strive to concoct narratives that transcends the conventional concepts of advertising setting new benchmarks and defying norms. We think with our
              hearts, knowing that audience will connect to our stories on a
              deeper level than just features and benefits. That's why we focus
              on crafting captivating narratives that capture your brand's core
              essence. Our excellent team of talented and creative writers and
              designers will effortlessly craft brand messages that spark an
              emotional connection and builds unwavering brand loyalty. We don’t
              aim to build brands, we desire to build a legacy.
            </p>
          </div>
          <div className="dot-data">
            <img src={SmallDot} alt="" />
          </div>
          <div>
            <img src={SideImg} alt="" />
          </div>
        </div>
        {/* <section className="service-video">
          <img src={Video} alt="" />
          <span className="play-icon">
            <i class="fa-regular fa-circle-play"></i>
          </span>
        </section> */}
        <section className="about-service">
          <div className="main-section">
            <div className="grid grid-cols-1 sm:grid-cols-2">
              <div className="sm:pr-32 ">
                <h3 className="my-1 text-2xl font-bold text-white sm:text-4xl sm:my-3">
                  Ignite Your Brand’s Success Story with Kreative Sparkz
                </h3>
                <p className="text-white">
                  We're a comprehensive creative branding agency, offering a
                  range of services from user-generated content (UGCs) and
                  animation to motion graphics, TV commercials (TVCs), and web
                  development. We design and develop content that resonates
                  deeply with your target audience, propelling your brand's
                  success story to new heights.
                </p>

                <div className="mt-2">
                  <span>
                    <NavLink to="/portfolio">
                      <span className="flex items-center text-base font-medium text-white">
                        Portfolio <img src={Arrow} alt="" />
                      </span>
                    </NavLink>
                    {/* <a className="flex items-center text-white" href="#">
                      <small className="text-base font-medium">
                        Our Portfolio
                      </small>
                      <img src={Arrow} alt="" />
                    </a> */}
                  </span>
                </div>
              </div>
              <div>
                <AccordionService item={itemPh} style={"white"} />
              </div>
            </div>
          </div>
        </section>
        <section className="works-service">
          <div className="main-section">
            <div className="m-auto text-center sm:w-1/2">
              <span class="dark-sub-head">
                Everything Sparkles at Kreative Sparkz{" "}
              </span>
              <p className="mt-3">
                Unleash your brand's potential. Explore our Kreative Compendium,
                showcasing our vision-to-victory success stories across a wide
                range of brands.
              </p>
            </div>
            <div className="grid grid-cols-2 gap-5 mt-6 sm:grid-cols-4">
              <div className="work work-one"></div>
              <div className="work work-two"></div>
              <div className="work work-three"></div>
              <div className="work work-four"></div>
              <div className="work work-five"></div>
              <div className="work work-six"></div>
              <div className="work work-eight"></div>
              <div className="work work-seven"></div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
