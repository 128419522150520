import React from "react";
import ThanksImg from "../img/thank-you.svg";
import "./thank.css";
import { NavLink } from "react-router-dom";
import logo from "../img/logo-red.png";

export const Thanks = () => {
  return (
    <div class="bg-[#f3eaec] ">
      <div className="w-full h-screen columns-1" >
        <div class="logo-fix px-16 pt-3">
          <NavLink to="/">
            <img className="w-12 logo" src={logo} alt="log"  />
          </NavLink>{" "}
        </div>
        <div class="thank-you flex flex-col items-center gap-6">
          <img src={ThanksImg} className="w-1/3" />
          <div class="thanks-msg text-center">
            <h5 className="mb-1">Your email has been sent.</h5>
            <h6>We'll get back tou shortly.</h6>
            <p class="pt-3">
              In the meantime check out our{" "}
              <NavLink to="/">kreative case studies </NavLink> or know more{" "}
              <NavLink to="/">about us</NavLink>{" "}
            </p>
          </div>
          <NavLink to="/" className="flex justify-center btn btn-primary">
            <span className="z-20">Back to Home</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
