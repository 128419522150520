import React, { useState } from "react";
import logo from "../img/logo.png";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

import ReImg from "../img/reImg.png"



export const Footer = () => {
  const [recaptchaResponse, setRecaptchaResponse] = useState(null);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    user_name: "",
    user_phone: "",
    user_email: "",
    user_topic: "Socail Branding",
    user_add_details: "",
    phone_code: "(+91)",

  });

  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    const dataToSend = {
      ...formData,
      "g-recaptcha-response": recaptchaResponse,
    };
    try {
      const res = await axios.post("/emails.php", dataToSend);
      const responseData = res.data;

      if (responseData === 'mandatory') {
        alert('Please fill in all data');
      } else if (responseData === 'captcha fail' || responseData === 'verification fail') {
        alert('Please try again');
      } else if (responseData === 'done') {
        navigate('/thank-you');

        
      } else {
        alert('Unexpected error occurred');
      }
      
    } catch (error) {
      console.error("Error sending data:", error);
    }
  };
  const handleRecaptchaChange = (value) => {
    setRecaptchaResponse(value);
  };


  return (
    <div className="pb-2 footer main-section">
      <div className="grid gap-12 sm:grid-cols-2 ">
        <div>
          <img src={logo} alt="" />
          <div>
            <h1 className="mt-8 text-5xl font-black sm:text-6xl sm:mt-8">
              hello,
            </h1>
            <p className="mt-1 text-base leading-6 sm:mt-4 sm:text-2x4">
              We are Kreative Sparkz, a full-service sales & Brand Consulting
              Agency with over 10 years of experience. In a world woven woith
              virtual threads, we master the art of storytelling through digital
              space, crafting narratives that captivate hearts and minds.
            </p>
          </div>
          <div className="grid justify-between grid-cols-2">
            <div>
              <h6 className="footer-sub-head">services</h6>
              <ul>
                <li>
                  <a href="#"> social branding</a>
                </li>
                <li>
                  <a href="#"> interactive design</a>
                </li>
                <li>
                  <a href="#"> strategic marketing</a>
                </li>
                <li>
                  <a href="#"> brand consulting</a>
                </li>
                <li>
                  <a href="#"> content writing</a>
                </li>
              </ul>
            </div>
            <div>
              <h6 className="footer-sub-head">get started</h6>
              <ul>
                <li>
                  <a href="#">portfolio</a>
                </li>
                <li>
                  <a href="#">clients</a>
                </li>
                <li>
                  <a href="#">contact us</a>
                </li>
                <li>
                  <a href="#">about us</a>
                </li>
                <li>
                  <a href="#">our kreators</a>
                </li>
                <li>
                  <a href="#">careers</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="border-b sm:py-2 border-white-500">
              <label>Full Name *</label>
              <input
                className="w-full px-2 py-1 mr-3 leading-tight bg-transparent border-none appearance-none text-white-700 focus:outline-none"
                type="text"
                aria-label="Full name"
                id="name"
                      name="user_name"
                      onChange={handleChange}
                      value={formData.user_name}
              />
            </div>
            <div className="grid gap-5 mt-5 sm:gap-12 sm:grid-cols-2 sm:mt-8">
              <div className="border-b sm:py-2 border-white-500">
                <label>Email *</label>
                <input
                  className="w-full px-2 py-1 mr-3 leading-tight bg-transparent border-none appearance-none text-white-700 focus:outline-none"
                  type="email"
                  aria-label="Full name"
                  id="mail"
                  name="user_email"
                  value={formData.user_email}
                  onChange={handleChange}
                />
              </div>
              <div className="sm:py-2">
                <label>Phone number *</label>

                <div className="flex gap-4">
                  <div className="w-32 border-b border-white-500">
                    <div className="relative ">
                      <select
                        className="w-full px-2 py-1 mr-3 leading-tight bg-transparent border-none appearance-none text-white-700 focus:outline-none"
                        id="phone-code"
                        name="phone_code"
                              value={formData.phone_code}
                              onChange={handleChange}
                      >
                         <option
                          className="text-gray-700"
                          data-countrycode="IN"
                          value="91"
                        >
                          India (+91)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="DZ"
                          value="213"
                        >
                          Algeria (+213)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AD"
                          value="376"
                        >
                          Andorra (+376)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AO"
                          value="244"
                        >
                          Angola (+244)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AI"
                          value="1264"
                        >
                          Anguilla (+1264)
                        </option>
                       
                        <option
                          className="text-gray-700"
                          data-countrycode="AG"
                          value="1268"
                        >
                          Antigua &amp; Barbuda (+1268)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AR"
                          value="54"
                        >
                          Argentina (+54)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AM"
                          value="374"
                        >
                          Armenia (+374)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AW"
                          value="297"
                        >
                          Aruba (+297)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AU"
                          value="61"
                        >
                          Australia (+61)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AT"
                          value="43"
                        >
                          Austria (+43)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AZ"
                          value="994"
                        >
                          Azerbaijan (+994)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BS"
                          value="1242"
                        >
                          Bahamas (+1242)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BH"
                          value="973"
                        >
                          Bahrain (+973)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BD"
                          value="880"
                        >
                          Bangladesh (+880)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BB"
                          value="1246"
                        >
                          Barbados (+1246)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BY"
                          value="375"
                        >
                          Belarus (+375)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BE"
                          value="32"
                        >
                          Belgium (+32)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BZ"
                          value="501"
                        >
                          Belize (+501)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BJ"
                          value="229"
                        >
                          Benin (+229)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BM"
                          value="1441"
                        >
                          Bermuda (+1441)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BT"
                          value="975"
                        >
                          Bhutan (+975)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BO"
                          value="591"
                        >
                          Bolivia (+591)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BA"
                          value="387"
                        >
                          Bosnia Herzegovina (+387)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BW"
                          value="267"
                        >
                          Botswana (+267)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BR"
                          value="55"
                        >
                          Brazil (+55)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BN"
                          value="673"
                        >
                          Brunei (+673)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BG"
                          value="359"
                        >
                          Bulgaria (+359)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BF"
                          value="226"
                        >
                          Burkina Faso (+226)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="BI"
                          value="257"
                        >
                          Burundi (+257)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KH"
                          value="855"
                        >
                          Cambodia (+855)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CM"
                          value="237"
                        >
                          Cameroon (+237)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CA"
                          value="1"
                        >
                          Canada (+1)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CV"
                          value="238"
                        >
                          Cape Verde Islands (+238)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KY"
                          value="1345"
                        >
                          Cayman Islands (+1345)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CF"
                          value="236"
                        >
                          Central African Republic (+236)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CL"
                          value="56"
                        >
                          Chile (+56)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CN"
                          value="86"
                        >
                          China (+86)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CO"
                          value="57"
                        >
                          Colombia (+57)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KM"
                          value="269"
                        >
                          Comoros (+269)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CG"
                          value="242"
                        >
                          Congo (+242)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CK"
                          value="682"
                        >
                          Cook Islands (+682)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CR"
                          value="506"
                        >
                          Costa Rica (+506)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="HR"
                          value="385"
                        >
                          Croatia (+385)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CU"
                          value="53"
                        >
                          Cuba (+53)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CY"
                          value="90392"
                        >
                          Cyprus North (+90392)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CY"
                          value="357"
                        >
                          Cyprus South (+357)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CZ"
                          value="42"
                        >
                          Czech Republic (+42)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="DK"
                          value="45"
                        >
                          Denmark (+45)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="DJ"
                          value="253"
                        >
                          Djibouti (+253)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="DM"
                          value="1809"
                        >
                          Dominica (+1809)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="DO"
                          value="1809"
                        >
                          Dominican Republic (+1809)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="EC"
                          value="593"
                        >
                          Ecuador (+593)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="EG"
                          value="20"
                        >
                          Egypt (+20)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SV"
                          value="503"
                        >
                          El Salvador (+503)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GQ"
                          value="240"
                        >
                          Equatorial Guinea (+240)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="ER"
                          value="291"
                        >
                          Eritrea (+291)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="EE"
                          value="372"
                        >
                          Estonia (+372)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="ET"
                          value="251"
                        >
                          Ethiopia (+251)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="FK"
                          value="500"
                        >
                          Falkland Islands (+500)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="FO"
                          value="298"
                        >
                          Faroe Islands (+298)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="FJ"
                          value="679"
                        >
                          Fiji (+679)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="FI"
                          value="358"
                        >
                          Finland (+358)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="FR"
                          value="33"
                        >
                          France (+33)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GF"
                          value="594"
                        >
                          French Guiana (+594)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PF"
                          value="689"
                        >
                          French Polynesia (+689)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GA"
                          value="241"
                        >
                          Gabon (+241)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GM"
                          value="220"
                        >
                          Gambia (+220)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GE"
                          value="7880"
                        >
                          Georgia (+7880)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="DE"
                          value="49"
                        >
                          Germany (+49)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GH"
                          value="233"
                        >
                          Ghana (+233)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GI"
                          value="350"
                        >
                          Gibraltar (+350)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GR"
                          value="30"
                        >
                          Greece (+30)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GL"
                          value="299"
                        >
                          Greenland (+299)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GD"
                          value="1473"
                        >
                          Grenada (+1473)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GP"
                          value="590"
                        >
                          Guadeloupe (+590)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GU"
                          value="671"
                        >
                          Guam (+671)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GT"
                          value="502"
                        >
                          Guatemala (+502)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GN"
                          value="224"
                        >
                          Guinea (+224)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GW"
                          value="245"
                        >
                          Guinea - Bissau (+245)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="GY"
                          value="592"
                        >
                          Guyana (+592)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="HT"
                          value="509"
                        >
                          Haiti (+509)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="HN"
                          value="504"
                        >
                          Honduras (+504)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="HK"
                          value="852"
                        >
                          Hong Kong (+852)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="HU"
                          value="36"
                        >
                          Hungary (+36)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="IS"
                          value="354"
                        >
                          Iceland (+354)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="ID"
                          value="62"
                        >
                          Indonesia (+62)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="IR"
                          value="98"
                        >
                          Iran (+98)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="IQ"
                          value="964"
                        >
                          Iraq (+964)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="IE"
                          value="353"
                        >
                          Ireland (+353)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="IL"
                          value="972"
                        >
                          Israel (+972)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="IT"
                          value="39"
                        >
                          Italy (+39)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="JM"
                          value="1876"
                        >
                          Jamaica (+1876)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="JP"
                          value="81"
                        >
                          Japan (+81)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="JO"
                          value="962"
                        >
                          Jordan (+962)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KZ"
                          value="7"
                        >
                          Kazakhstan (+7)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KE"
                          value="254"
                        >
                          Kenya (+254)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KI"
                          value="686"
                        >
                          Kiribati (+686)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KP"
                          value="850"
                        >
                          Korea North (+850)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KR"
                          value="82"
                        >
                          Korea South (+82)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KW"
                          value="965"
                        >
                          Kuwait (+965)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KG"
                          value="996"
                        >
                          Kyrgyzstan (+996)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LA"
                          value="856"
                        >
                          Laos (+856)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LV"
                          value="371"
                        >
                          Latvia (+371)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LB"
                          value="961"
                        >
                          Lebanon (+961)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LS"
                          value="266"
                        >
                          Lesotho (+266)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LR"
                          value="231"
                        >
                          Liberia (+231)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LY"
                          value="218"
                        >
                          Libya (+218)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LI"
                          value="417"
                        >
                          Liechtenstein (+417)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LT"
                          value="370"
                        >
                          Lithuania (+370)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LU"
                          value="352"
                        >
                          Luxembourg (+352)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MO"
                          value="853"
                        >
                          Macao (+853)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MK"
                          value="389"
                        >
                          Macedonia (+389)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MG"
                          value="261"
                        >
                          Madagascar (+261)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MW"
                          value="265"
                        >
                          Malawi (+265)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MY"
                          value="60"
                        >
                          Malaysia (+60)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MV"
                          value="960"
                        >
                          Maldives (+960)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="ML"
                          value="223"
                        >
                          Mali (+223)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MT"
                          value="356"
                        >
                          Malta (+356)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MH"
                          value="692"
                        >
                          Marshall Islands (+692)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MQ"
                          value="596"
                        >
                          Martinique (+596)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MR"
                          value="222"
                        >
                          Mauritania (+222)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="YT"
                          value="269"
                        >
                          Mayotte (+269)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MX"
                          value="52"
                        >
                          Mexico (+52)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="FM"
                          value="691"
                        >
                          Micronesia (+691)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MD"
                          value="373"
                        >
                          Moldova (+373)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MC"
                          value="377"
                        >
                          Monaco (+377)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MN"
                          value="976"
                        >
                          Mongolia (+976)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MS"
                          value="1664"
                        >
                          Montserrat (+1664)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MA"
                          value="212"
                        >
                          Morocco (+212)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MZ"
                          value="258"
                        >
                          Mozambique (+258)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="MN"
                          value="95"
                        >
                          Myanmar (+95)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NA"
                          value="264"
                        >
                          Namibia (+264)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NR"
                          value="674"
                        >
                          Nauru (+674)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NP"
                          value="977"
                        >
                          Nepal (+977)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NL"
                          value="31"
                        >
                          Netherlands (+31)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NC"
                          value="687"
                        >
                          New Caledonia (+687)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NZ"
                          value="64"
                        >
                          New Zealand (+64)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NI"
                          value="505"
                        >
                          Nicaragua (+505)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NE"
                          value="227"
                        >
                          Niger (+227)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NG"
                          value="234"
                        >
                          Nigeria (+234)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NU"
                          value="683"
                        >
                          Niue (+683)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NF"
                          value="672"
                        >
                          Norfolk Islands (+672)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NP"
                          value="670"
                        >
                          Northern Marianas (+670)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="NO"
                          value="47"
                        >
                          Norway (+47)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="OM"
                          value="968"
                        >
                          Oman (+968)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PW"
                          value="680"
                        >
                          Palau (+680)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PA"
                          value="507"
                        >
                          Panama (+507)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PG"
                          value="675"
                        >
                          Papua New Guinea (+675)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PY"
                          value="595"
                        >
                          Paraguay (+595)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PE"
                          value="51"
                        >
                          Peru (+51)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PH"
                          value="63"
                        >
                          Philippines (+63)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PL"
                          value="48"
                        >
                          Poland (+48)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PT"
                          value="351"
                        >
                          Portugal (+351)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="PR"
                          value="1787"
                        >
                          Puerto Rico (+1787)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="QA"
                          value="974"
                        >
                          Qatar (+974)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="RE"
                          value="262"
                        >
                          Reunion (+262)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="RO"
                          value="40"
                        >
                          Romania (+40)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="RU"
                          value="7"
                        >
                          Russia (+7)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="RW"
                          value="250"
                        >
                          Rwanda (+250)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SM"
                          value="378"
                        >
                          San Marino (+378)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="ST"
                          value="239"
                        >
                          Sao Tome &amp; Principe (+239)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SA"
                          value="966"
                        >
                          Saudi Arabia (+966)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SN"
                          value="221"
                        >
                          Senegal (+221)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CS"
                          value="381"
                        >
                          Serbia (+381)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SC"
                          value="248"
                        >
                          Seychelles (+248)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SL"
                          value="232"
                        >
                          Sierra Leone (+232)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SG"
                          value="65"
                        >
                          Singapore (+65)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SK"
                          value="421"
                        >
                          Slovak Republic (+421)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SI"
                          value="386"
                        >
                          Slovenia (+386)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SB"
                          value="677"
                        >
                          Solomon Islands (+677)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SO"
                          value="252"
                        >
                          Somalia (+252)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="ZA"
                          value="27"
                        >
                          South Africa (+27)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="ES"
                          value="34"
                        >
                          Spain (+34)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="LK"
                          value="94"
                        >
                          Sri Lanka (+94)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SH"
                          value="290"
                        >
                          St. Helena (+290)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="KN"
                          value="1869"
                        >
                          St. Kitts (+1869)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SC"
                          value="1758"
                        >
                          St. Lucia (+1758)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SD"
                          value="249"
                        >
                          Sudan (+249)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SR"
                          value="597"
                        >
                          Suriname (+597)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SZ"
                          value="268"
                        >
                          Swaziland (+268)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SE"
                          value="46"
                        >
                          Sweden (+46)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="CH"
                          value="41"
                        >
                          Switzerland (+41)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="SI"
                          value="963"
                        >
                          Syria (+963)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TW"
                          value="886"
                        >
                          Taiwan (+886)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TJ"
                          value="7"
                        >
                          Tajikstan (+7)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TH"
                          value="66"
                        >
                          Thailand (+66)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TG"
                          value="228"
                        >
                          Togo (+228)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TO"
                          value="676"
                        >
                          Tonga (+676)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TT"
                          value="1868"
                        >
                          Trinidad &amp; Tobago (+1868)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TN"
                          value="216"
                        >
                          Tunisia (+216)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TR"
                          value="90"
                        >
                          Turkey (+90)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TM"
                          value="7"
                        >
                          Turkmenistan (+7)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TM"
                          value="993"
                        >
                          Turkmenistan (+993)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TC"
                          value="1649"
                        >
                          Turks &amp; Caicos Islands (+1649)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="TV"
                          value="688"
                        >
                          Tuvalu (+688)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="UG"
                          value="256"
                        >
                          Uganda (+256)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="UA"
                          value="380"
                        >
                          Ukraine (+380)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="AE"
                          value="971"
                        >
                          United Arab Emirates (+971)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="UY"
                          value="598"
                        >
                          Uruguay (+598)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="UZ"
                          value="7"
                        >
                          Uzbekistan (+7)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="VU"
                          value="678"
                        >
                          Vanuatu (+678)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="VA"
                          value="379"
                        >
                          Vatican City (+379)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="VE"
                          value="58"
                        >
                          Venezuela (+58)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="VN"
                          value="84"
                        >
                          Vietnam (+84)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="VG"
                          value="84"
                        >
                          Virgin Islands - British (+1284)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="VI"
                          value="84"
                        >
                          Virgin Islands - US (+1340)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="WF"
                          value="681"
                        >
                          Wallis &amp; Futuna (+681)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="YE"
                          value="969"
                        >
                          Yemen (North)(+969)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="YE"
                          value="967"
                        >
                          Yemen (South)(+967)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="ZM"
                          value="260"
                        >
                          Zambia (+260)
                        </option>
                        <option
                          className="text-gray-700"
                          data-countrycode="ZW"
                          value="263"
                        >
                          Zimbabwe (+263)
                        </option>
                      </select>
                      <div className="absolute inset-y-0 right-0 flex items-center px-2 text-white rounded-lg	 pointer-events-none bg-[#353535] text-white-700 ">
                      <svg
                          className="w-4 h-4 fill-current"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="w-full border-b border-white-500">
                    <input
                      className="w-full py-1 mr-3 leading-tight bg-transparent border-none appearance-none text-white-700 focus:outline-none"
                      type="phone"
                      id="phone"
                      name="user_phone"
                            value={formData.user_phone}
                            onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 border-b sm:py-2 border-white-500 sm:mt-8">
              <label>Additional Detalis</label>
              <input
                className="w-full px-2 py-1 mr-3 leading-tight bg-transparent border-none appearance-none text-white-700 focus:outline-none"
                type="text"
                aria-label="Full name"
                name="user_add_details"
                value={formData.user_add_details}
                onChange={handleChange}
              />
            </div>
            <div className="mt-5 border-b sm:py-2 border-white-500 sm:mt-8">
              <label>Select Your Product </label>
              <div className="relative ">
                <select
                  className="w-full px-2 py-1 mr-3 leading-tight bg-transparent border-none appearance-none text-white-700 focus:outline-none"
                  id="details"
                  name="user_topic"
                  value={formData.user_topic}
                        onChange={handleChange}
                >
                  <option className="text-gray-700"> Social Branding</option>
                  <option className="text-gray-700"> Interactive Design</option>
                  <option className="text-gray-700">Strategic Marketing</option>
                  <option className="text-gray-700"> Brand Consulting</option>
                  <option className="text-gray-700">
                        Content Writing
                        </option>
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 text-white rounded-lg	 pointer-events-none bg-[#353535] text-white-700 ">
                <svg
                    className="w-4 h-4 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2">
            <div class="gap-3 d-flex mt-2 ">
                    {/* <label className="mt-8 text-xs">Calculate the number</label>

                    <div className="flex h-auto gap-2">
                      <div>
                        <img src={ReImg} alt="" className="w-56 " />
                        </div>
                      <div className="h-auto"><input className="h-full px-2 text-black border border-black" type="text" value={formData.reNumber} name="reNumber"
                     onChange={handleChange} min="2" max="10" /></div>
                    </div> */}
                      <div>
                        <ReCAPTCHA
                          sitekey="6LcQ4mYpAAAAAF8JcmXFVZS2TcwmzA7ScW-TLbNU"
                          onChange={handleRecaptchaChange}
                        />
                      </div>
                    
                    </div>
              <div className="flex justify-start">
                <button
                  className="flex justify-center mt-5 btn btn-secondry"
                  onClick={handleSubmit}
                >
                  <span className="z-20">Submit</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between sm:flex-row">
        <div className="flex gap-8 mt-8 mb-2 sm:mt-0">
          <a
            href="https://in.linkedin.com/company/kreative-sparkz"
            target="_blank"
          >
            {" "}
            <i class="fa-brands fa-linkedin fa-xl"></i>
          </a>
          <a
            href="https://www.youtube.com/@kreativesparkz/videos"
            target="_blank"
          >
            <i class="fa-brands fa-youtube fa-xl"></i>
          </a>
          <a href="https://www.instagram.com/kreativesparkz" target="_blank">
            <i class="fa-brands fa-instagram fa-xl"></i>
          </a>
          <a href="https://www.facebook.com/kreativesparkz" target="_blank">
            <i class="fa-brands fa-facebook fa-xl"></i>
          </a>
        </div>
        <div>
          <ul className="flex ">
            <li className="mx-1">
              <a href="#">© Kreative Sparkz</a>
            </li>
            <li className="mx-1">
              <a href="#">Privacy </a>
            </li>
            <li className="mx-1">
              <a href="#">Terms </a>
            </li>
            <li className="mx-1">
              <a href="#">Sitemap</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
